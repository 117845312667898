import React, { useState } from "react";
import axios from "axios";
import {
  Header,
  Label,
  ErrorMessage,
  success,
  errorToast,
  Spinner,
} from "../components/shared";
import { Arrow } from "../assets/icons";
import { useForm } from "react-hook-form";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getServiceMail = async (data) => {
    switch (data) {
      case "technical":
        return "https://backend.vertgrid.com/email/landing/technicalSupport";

      case "distribution":
        return "https://backend.vertgrid.com/email/landing/distributor";

      case "investment":
        return "https://backend.vertgrid.com/email/landing/investor";

      case "buying":
        return "https://backend.vertgrid.com/email/landing/product";

      default:
        return "https://backend.vertgrid.com/email/landing/product";
    }
  };

  const submitToEmail = async (data) => {
    const serviceUrl = await getServiceMail(data.service);
    console.log(serviceUrl,'before sending')
    if (serviceUrl !== null || "") {
      const emaildata = {
        fullName: data.fullName,
        emailAddress: data.emailAddress,
        message: data.message,
        locationCountry: data.locationCountry,
        locationState: data.locationState,
        phoneNumber: data.phoneNumber,
        organization: data.organizatiomn,
      };
      return await axios
        .post(`${serviceUrl}`, emaildata)
        .then((res) => {
          console.log(res.data);
          const { type, msg } = res.data;
          if (type === "success") {
            success(msg);
            return true;
          } else {
            setLoading(false);
            errorToast(msg);
            return false;
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response.message);
        });
    } else {
      errorToast("please select a service");
      return;
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);

    const result = await submitToEmail(data);
    if (result) {
      setLoading(false);
      reset({
        service: "",
        fullName: "",
        emailAddress: "",
        message: "",
        locationCountry: "",
        locationState: "",
        phoneNumber: "",
        organization: "",
      });
      return;
    }
  };
  return (
    <div>
      <Header title="CONTACT US" />
      <div className="w-10/12 xl:w-9/12 mx-auto my-16 py-16 bg-[#0930400A]/5">
        <div className="w-11/12 lg:w-9/12 xl:w-7/12 mx-auto py-8 font-clash">
          <div className="mb-8">
            <Label label="What service do you need?" />
            <select
              className="contact-input pl-20"
              {...register("service", {
                // required: { value: true, message: "Full Name is required" },
              })}
            >
              <option>What service do you need?</option>
              <option value="technical">Technical Support</option>
              <option value="distribution">Become a Distributor</option>
              <option value="investment">Become an Investor</option>
              <option value="buying">Buy a product</option>
            </select>
            {errors.service && (
              <ErrorMessage message={errors.service?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Full Name" />
            <input
              className="contact-input"
              placeholder="Enter full name"
              {...register("fullName", {
                required: { value: true, message: "Full Name is required" },
              })}
            />
            {errors.fullName && (
              <ErrorMessage message={errors.fullName?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Name of Organization" />
            <input
              className="contact-input"
              placeholder="Enter your organization name"
              {...register("organization", {
                // required: { value: true, message: "Full Name is required" },
              })}
            />
            {/* {errors.organizationName && (
              <ErrorMessage message={errors.organizationName?.message} />
            )} */}
          </div>
          <div className="mb-8">
            <Label label="Phone Number" />
            <input
              className="contact-input"
              placeholder="Enter your phone number"
              {...register("phoneNumber", {
                // required: { value: true, message: "Phone Number is required" },
              })}
            />
            {errors.phoneNumber && (
              <ErrorMessage message={errors.phoneNumber?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Email Address" />
            <input
              className="contact-input"
              placeholder="Enter your email address"
              {...register("emailAddress", {
                required: { value: true, message: "Email Address is required" },
              })}
            />
            {errors.emailAddress && (
              <ErrorMessage message={errors.emailAddress?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Location (Country)" />

            <input
              className="contact-input"
              placeholder="Country"
              {...register("locationCountry", {
                required: { value: true, message: "Country is required" },
              })}
            />
            {errors.locationCountry && (
              <ErrorMessage message={errors.locationCountry?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Location (State)" />

            <input
              className="contact-input"
              placeholder="State"
              {...register("locationState", {
                required: { value: true, message: "State is required" },
              })}
            />
            {errors.locationState && (
              <ErrorMessage message={errors.locationState?.message} />
            )}
          </div>
          <div className="mb-8">
            <Label label="Message" />
            <textarea
              className="contact-input resize-none h-60"
              placeholder="Write your message"
              {...register("message", {
                // required: { value: true, message: "Full Name is required" },
              })}
            ></textarea>
            {errors.message && (
              <ErrorMessage message={errors.message?.message} />
            )}
          </div>
          <div className="font-visby">
            <button
              onClick={handleSubmit(onSubmit)}
              className="contact-us-button"
            >
              {loading ? <Spinner /> : "SEND MESSAGE"}

              <img
                src={Arrow}
                alt=""
                className="ml-3 w-8 md:w-10 lg:w-12 xl:w-14"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
