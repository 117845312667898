import React from "react";
import { Header, ContactSection } from "../components/shared";
import { CareerPageImage } from "../assets/images";
const Career = () => {
  return (
    <div>
      <Header title="CAREER" />
      <div className="w-10/12 mx-auto  my-16">
        <div className="relative w-fit">
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-darkTeal/50"></div>
          <img src={CareerPageImage} alt="" />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-12  mt-10   text-grey2 font-clash">
          <div className="col-span-1 md:col-span-7    place-self-center">
            <div className="mb-5">
              <h5 className="text-xl font-semibold w-fit py-1  border-b-2 border-b-darkTeal uppercase">
                CAREER
              </h5>
            </div>

            <p className=" text-sm md:text-base lg:text-xl mb-5">
              At Cellone, our people come first. We are a team of committed
              professionals motivated by our mission of solving the energy
              access problem in Africa through disruptive innovations.
            </p>
            <p className=" text-sm md:text-base lg:text-xl mb-5">
              If you are mission-driven, hardworking, and a great team player,
              please share your CV with us with your expertise as your mail
              subject.
            </p>

            <p className="font-semibold text-base lg:text-lg">
              Email:
              <a
                href="mailto:hr@cellonetechnologies.com"
                className="underline text-darkTeal font-medium text-lg"
              >
                hr@cellonetechnologies.com
              </a>
            </p>
          </div>
          <div className="hidden md:block md:col-span-5 "></div>
        </div>
      </div>
      <ContactSection />
    </div>
  );
};

export default Career;
