import React from "react";
import { ArrowYellow } from "../../assets/icons";

const BlogCard = ({item}) => {
  return (
    <div>
      <div>
        <img src={item.image} alt="" className="w-full" />
      </div>
      <div className="bg-darkTeal text-white p-5">
        <h5 className="font-semibold text-xl my-4">
          {item.title}
        </h5>
        <p className="mb-4">
          {item.shortDescription}
        </p>

        <button className="text-yellow flex items-center">
          Read More
          <img src={ArrowYellow} alt="" className="ml-3" />
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
