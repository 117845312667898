import React from "react";
import { useParams } from "react-router-dom";
import {
  ProductHeader,
  YellowBorderTitle,
  ContactSection,
} from "../components/shared";
import { products } from "../data";
import { Shield } from "../assets/icons";

const Product = () => {
  const { id } = useParams();

  const product = products.find((item) => item.id === id);
  console.log(product, "here");
  return (
    <div className="font-clash">
      <ProductHeader title={product.name} />

      <div className="py-12">
        <div className="w-10/12 mx-auto grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-12 place-items-center">
          <div className="col-span-7">
            <YellowBorderTitle title="PRODUCTS" />
            <div className="my-8">
              <p className="text-xl md:text-2xl text-grey1">{product.description}</p>
            </div>
          </div>
          <div className="col-span-5  relative">
            <img
              src={product.image2}
              alt="product-diagram"
              className=" w-11/12 md:w-10/12 lg:w-9/12 mx-auto"
            />
          </div>
        </div>

        <div className="w-10/12 mx-auto my-8">
          <h4 className="text-black font-medium  text-4xl mb-8">Features</h4>

          {product.features.map((item, i) => (
            <div key={i} className="flex items center mb-4">
              <img src={Shield} alt="" className="w-7 h-7 mr-3"/>
              <span className="text-xl text-grey1">{item}</span>
            </div>
          ))}
        </div>
      </div>
      <ContactSection />
    </div>
  );
};

export default Product;
