import React from "react";

const Header = ({ title }) => {
  return (
    <div className="bg-hero bg-no-repeat bg-center bg-cover text-white p-3  w-full  h-52  md:h-60 lg:h-[320px] flex items-center  relative">
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-darkTeal/80"></div>
      <div className="w-fit mx-auto z-30">
        <h3 className="font-semibold text-3xl sm:text-4xl md:text-5xl lg:text-6xl uppercase font-clash  px-12">
          {title}
        </h3>
      </div>
    </div>
  );
};

export default Header;
