import { Routes, Route, useLocation } from "react-router-dom";
import {
  LandingPage,
  Product,
  Application,
  Blog,
  ContactUs,
  Career,
  PrivacyPolicy,
  FAQS,
  Error404,
} from "./pages";
import { Navbar, Footer } from "./components/shared";
import { useEffect } from "react";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
};

function App() {
  return (
    <div className="App font-visby relative w-full flex flex-col justify-between">
      <ScrollToTop>
        <Navbar />
        <div className="mt-0 lg:mt-40">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/products/:id" element={<Product />} />
            <Route path="/application" element={<Application />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/faqs" element={<FAQS />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
