import React, { useState } from "react";
import { Header } from "../components/shared";
import { CaretDown, FAQQuestionIcon } from "../assets/icons";
import { faqQuestions } from "../data";

const Question = ({ item }) => {
  const [open, setOpen] = useState(false);
  const toggleQuestion = () => {
    setOpen(!open);
  };
  return (
    <div className="border border-[#C4C4C4] py-3 px-3 md:py-4 md:px-5 xl:py-5 xl:px-8 relative mb-5 font-clash">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img src={FAQQuestionIcon} alt="" className="w-7 h-7 mr-5" />
          <p className="text-black font-medium text-sm sm:text-base md:text-lg xl:text-xl">
            {item.question}
          </p>
        </div>

        <div className="pl-3" onClick={toggleQuestion}>
          <img
            src={CaretDown}
            alt=""
            className="w-3 h-3 "
            
          />
        </div>
      </div>
      <div
        className={` ${
          open ? "block" : "hidden"
        } border-t border-t-[#C4C4C4] mt-5 pt-5`}
      >
        {item.answer.map((ans, i) => (
          <p
            key={`dfg${i}`}
            className="text-xs sm:text-sm md:text-base xl:text-lg mb-3"
          >
            {ans}
          </p>
        ))}
      </div>
    </div>
  );
};

const FAQS = () => {
  return (
    <div>
      <Header title="FREQUENTLY ASKED QUESTIONS" />
      <div className="my-16 w-full px-4 md:px-12 lg:px-0  lg:w-10/12 mx-auto">
        <div>
          {faqQuestions.map((questionitem, i) => (
            <Question key={i} item={questionitem} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQS;
