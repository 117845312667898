import React from "react";
import { Header, ContactSection } from "../components/shared";
import { Enterprise, Telecoms } from "../assets/images";
import { Dot } from "../assets/icons";

const applicationdata = {
  telecomapplication: [
    `Remote towers and systems`,
    `Back-up systems`,
    `Repeater stations`,
    `Base transmission stations (BTS)`,
    `Rural telephony`,
    `VSATs`,
    `Satellite earth stations`,
  ],
  telecomfeatures: [
    `Thermal Management`,
    `Configurable based on Load Demands`,
    `Supports all battery storage types / Hybrid`,
    `Remote Energy Management`,
    `Comprehensive Cloud Monitoring Platform`,
    `Full Featured Data Analytics Supports Predictive Alerting`,
    `DC and AC Load Distribution`,
    `-48V/-24V Positive Ground`,
    `SNMP Compatible`,
  ],
  enterpriseapplication: [
    `Application Cases`,
    `  Hospitality industry`,
    `  Healthcare industry`,
    `  Agricultural industry`,
    `  Manufacturing industry`,
    `  Food Processing`,
    `  Textile industry`,
    `  Brewing industry`,
    `  Financial industry`,
    `  Commercial office/buildings`,
  ],
  enterprisefeatures: [
    `Configurable Based on Load Demands`,
    `Remote Energy Management and Monitoring Platform`,
    `Powers DC and AC Loads`,
    `Scalable Based on Increasing Load Demand`,
    `7 years Warranty`,
  ],
};
const Application = () => {
  return (
    <div>
      <Header title="APPLICATION" />
      <div className="w-full px-6 md:px-12 lg:px-0 lg:w-10/12 mx-auto py-16 font-clash text-black">
        <div>
          <img src={Telecoms} alt="" />
        </div>
        <div className="lg:w-9/12 mt-6">
          <p className="mb-5 text-lg">
            Telecom infrastructure located in isolated and remote areas are
            usually far from any electrical grid and rely on captive power
            generation to operate. For communications providers, the ultimate
            goal is to establish self-sustainable mobile networks with higher
            efficiency and profitability and remain competitive in a lower
            Average-Revenue-Per-User (ARPU) environment. In remotely powered
            telecommunications installations, what matters most is
            efficiency,reliability and cost effectiveness.
          </p>

          <p className="font-medium text-lg mb-8">
            The power challenges of the telecommunication industry can be broken
            into two;
          </p>

          <p className="mb-2 font-medium text-lg" text-lg>
            {" "}
            1. Poor power supply in rural communities{" "}
          </p>
          <p className="mb-8">
            The grid in rural communities is often unreliable or nonexistent
            which poses a significant challenge for providing reliable mobile
            connectivity to the area.
          </p>

          <p className="mb-2 font-medium text-lg">2. High Services Cost</p>
          <p>
            The low population density of the rural areas makes rural telephony
            a commercial challenge. With a low number of mobile subscribers, the
            Average Revenue Per Unit (ARPU) of internet service providers is too
            low to sustain a profitable business model.
          </p>

          <p className="">
            These unique requirements of telecoms infrastructures are what the
            Cellone’s Plugspot R and Plugspot U are designed to meet in an
            exceptional way.
          </p>

          <div className="my-8">
            <div className="mb-12">
              <h4 className="font-medium text-3xl mb-4">Application Cases</h4>
              <div>
                {applicationdata.telecomapplication.map((item, i) => (
                  <div key={i} className="flex items-center mb-3">
                    <img src={Dot} alt="" className="w-1 h-1 mr-3" />
                    <span className="text-lg">{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="font-medium text-3xl mb-4">Features</h4>

              <div>
                {applicationdata.telecomfeatures.map((item, i) => (
                  <div key={i} className="flex items-center mb-3">
                    <img src={Dot} alt="" className="w-1 h-1 mr-3" />
                    <span className="text-lg">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <img src={Enterprise} alt="" />
        </div>

        <div className="lg:w-9/12 mt-8">
          <p className="mb-5 text-lg">
            In Africa, Businesses are frequently disrupted by consistent power
            outages and dependence on backup diesel generators that are highly
            expensive to operate and maintain do present considerable health and
            environmental challenges. The rising cost of diesel and occasional
            scarcity further reduces the reliability of diesel generators and
            cause businesses to lose numerous productive man-hours.
          </p>
          <p className="mb-5 text-lg">
            Plugspot-E is an all-in-one energy storage system which has an
            inverter, solar controllers, grid chargers and lithium batteries in
            a sleek and compact enclosure that is easily mounted on the wall or
            sits on a flat surface. It is a modular and scalable system with
            features tailored to deliver the unique energy requirements of
            businesses.
          </p>

          <div className="my-8">
            <div className="mb-12">
              <h4 className="font-medium text-3xl mb-4">Application Cases</h4>
              <div>
                {applicationdata.enterpriseapplication.map((item, i) => (
                  <div key={i} className="flex items-center mb-3">
                    <img src={Dot} alt="" className="w-1 h-1 mr-3" />
                    <span className="text-lg">{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="font-medium text-3xl mb-4">Features</h4>

              <div>
                {applicationdata.enterprisefeatures.map((item, i) => (
                  <div key={i} className="flex items-center mb-3">
                    <img src={Dot} alt="" className="w-1 h-1 mr-3" />
                    <span className="text-lg">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactSection />
    </div>
  );
};

export default Application;
