import React from "react";
// import { Link } from "react-router-dom";
import { AboutImage, CareerImage } from "../assets/images";
// import { ArrowWhite } from "../assets/icons";
import {
  ArrowButton,
  YellowBorderTitle,
  ContactSection,
  BlogCard,
} from "../components/shared";
import { products, blogdata } from "../data";

const Header = () => {
  return (
    <div className="bg-hero bg-repeat-x bg-cover bg-center min-h-[320px] lg:min-h-[580px] h-[50vh] md:h-[60vh] lg:h-[65vh]  xl:h-[75vh] flex items-center text-white relative overflow-hidden">
      <div className="hidden md:flex h-full w-full  md:items-center custom-header-gradient ">
        <div className="w-10/12 mx-auto z-30">
          <h3 className="font-medium text-xl sm:text-2xl md:text-4xl lg:text-5xl xl:text-7xl font-clash mb-3 whitespace-nowrap">
            Off-Grid And <br />
            On-Grid Smart <br />
            Power Solutions
          </h3>
          <h5 className=" text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl mb-4 font-visby">
            Clean and Renewable Energy
          </h5>
          
        </div>
      </div>
      <div className="md:mt-20 md:hidden h-full w-full  flex   custom-header-gradient-sm ">
        <div className=" z-30 w-10/12 mx-auto pt-4">
          <h3 className="font-medium text-xl md:text-4xl lg:text-5xl xl:text-7xl font-clash mb-3 whitespace-nowrap">
            Off-Grid And On-Grid <br />
            Smart Power Solutions
          </h3>
          <h5 className=" text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl mb-4 font-visby">
            Clean and Renewable Energy
          </h5>
          {/* <a href="https://vertgrid.com" target="_blank" rel="noreferrer">
            <button className="border-2 border-white text-base text-white font-bold uppercase py-3 px-5 rounded-[200px] flex items-center">
              VERTGRID
              <img src={ArrowWhite} alt="" className="ml-3 lg:ml-6 w-7" />
            </button>
          </a> */}
        </div>
      </div>
    </div>
  );
};
const AboutCellone = () => {
  return (
    <div className="py-12 w-10/12 mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 place-items-center font-clash">
      <div className="col-span-1">
        <img src={AboutImage} alt="" className="" />
      </div>
      <div className="col-span-1 text-grey1 py-4">
        <div className="lg:w-10/12 ">
          <div className="mb-6 w-full ">
            <h4 className="font-clash font-semibold text-2xl mb-4">
              About Cellone
            </h4>
            <h3 className="font-clash font-semibold text-3xl md:text-4xl text-darkTeal mb-4">
              Cellone is a clean tech engineering company <br />
            </h3>
            <p className="text-base md:text-lg  lg:text-xl ">
              Cellone is a clean-tech engineering company revolutionizing the
              way we power our world. We use state of the art technology to
              develop end to end smart pre-configured power systems called
              PluGSpot which are cost effective, modular and scalable and
              provides cheaper and reliable electricity for telecoms
              infrastructure, homes and enterprises.
            </p>
          </div>
          <div className="mb-6 ">
            <h4 className="font-clash font-semibold text-2xl text-darkTeal mb-4">
              Vision
            </h4>
            <p className="text-base md:text-lg  lg:text-xl">
              To enable a future powered by clean, renewable energy by
              developing and deploying advanced solar power technologies that
              are accessible, reliable and affordable.
            </p>
          </div>
          <div className="">
            <h4 className="font-clash font-semibold text-2xl text-darkTeal mb-4">
              Mission
            </h4>
            <p className="text-base md:text-lg  lg:text-xl">
              To drive the transition to a cleaner, more energy future, by
              providing affordable, reliable and smart solar energy solutions to
              businesses and households.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  function isEven(n) {
    n = Number(n);
    return n === 0 || !!(n && !(n % 2));
  }

  return (
    <div className="w-10/12 mx-auto py-4">
      <div className="mb-8">
        <YellowBorderTitle title="PRODUCTS" />
      </div>
      <div>
        {products.map((prod, i) => {
          const reverse = isEven(i);
          return (
            <div
              key={i}
              className="grid grid-cols-1 md:grid-cols-2 gap-6 place-items-center mb-12"
            >
              <div
                className={`${reverse ? "md:order-last" : ""} font-clash py-6`}
              >
                <h3 className="text-darkTeal  text-2xl lg:text-4xl font-semibold mb-5 uppercase">
                  {prod.name}
                </h3>
                <p className=" text-base lg:text-lg xl:text-xl mb-6 text-grey1">
                  {prod.description}
                </p>
                <div className="">
                  <ArrowButton
                    title="Learn More"
                    link={`/products/${prod.id}`}
                  />
                </div>
              </div>
              <div
                className={`${
                  reverse ? "justify-self-start" : "justify-self-end"
                }`}
              >
                <img src={prod.image1} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Career = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-12   bg-darkTeal text-white font-clash">
      <div className="col-span-1 md:col-span-7 p-4 w-10/12 text-white place-self-center">
        <div className="mb-5">
          <YellowBorderTitle title="CAREER" />
        </div>

        <p className="font-medium text-sm md:text-base lg:text-xl mb-5">
          At Cellone, our people come first. We are a team of committed
          professionals motivated by our mission of solving the energy access
          problem in Africa through disruptive innovations.
        </p>
        <p className="font-medium text-sm md:text-base lg:text-xl mb-5">
          If you are mission-driven, hardworking, and a great team player,
          please share your CV with us with your expertise as your mail subject.
        </p>

        <p className="font-semibold text-base lg:text-lg">
          Email:{" "}
          <a
            href="mailto:hr@cellonetechnologies.com"
            className="underline text-yellow font-medium text-lg"
          >
            hr@cellonetechnologies.com
          </a>
        </p>
      </div>
      <div className="hidden md:block md:col-span-5 ">
        <img src={CareerImage} alt="" className="w-full h-full " />
      </div>
    </div>
  );
};

const Blog = () => {
  return (
    <div className="w-10/12 mx-auto py-12">
      <div className="mb-8">
        <YellowBorderTitle title="BLOG" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogdata.slice(0, 1).map((item, i) => (
          <BlogCard key={i} item={item} />
        ))}
      </div>
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="">
      <Header />
      <AboutCellone />
      <Products />
      <Career />
      <Blog />
      <ContactSection />
    </div>
  );
};

export default LandingPage;
