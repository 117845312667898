import { useState } from "react";
import { Logo } from "../../assets/images";
import {
  EmailWhite,
  PhoneWhite,
  MobileNavIcon,
  CaretDown,
} from "../../assets/icons";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { navitems } from "../../data";

const CustomDropDown = ({ name, items }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const closeDropdown = () => {
    setOpen(false);
  };
  const ref = useOutsideClick(closeDropdown);
  return (
    <div ref={ref} className="relative ">
      <div
        className="cursor-pointer py-2 flex items-center font-clash"
        onClick={toggleOpen}
        onBlur={() => setOpen(false)}
      >
        {name} <img src={CaretDown} alt="" className="ml-2" />
      </div>
      {open && (
        <div
          className={`bg-white mt-3 text-darkteal  z-[50] absolute  top-[50%]  rounded min-w-[5rem] shadow-[6px 6px 25px 1px rgba(0, 0, 0, 0.06)] w-fit  cursor-pointer `}
        >
          <ul onClick={closeDropdown}>
            {items.map((item, i) => (
              <li
                key={item.name}
                className="border-b border-b-grey1 py-2 px-5 whitespace-nowrap font-clash font-medium hover:bg-darkTeal hover:text-white first:border-t first:border-t-grey1 first:mt-3"
              >
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleClose = () => {
    setMobileMenu(false);
  };

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const navref = useOutsideClick(handleClose);

  return (
    <div className=" bg-darkTeal w-full text-grey1 z-[50] ">
      <div className=" hidden lg:block lg:fixed lg:top-0 lg:left-0 lg:right-0">
        <div className=" w-full bg-darkTeal text-white py-3">
          <div className=" w-10/12 mx-auto flex items-center justify-between font-clash">
            <Link to="/" className="">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="flex items-center ">
              <div className="flex items-center mr-6">
                <img src={PhoneWhite} alt="" className="mr-3" />{" "}
                <span>+2349160370984</span>
              </div>
              <div className="flex items-center">
                <img src={EmailWhite} alt="" className="mr-3" />{" "}
                <span>contact@cellonetechnologies.com</span>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-white w-full">
          <div className=" lg:w-11/12 xl:w-10/12 mx-auto py-4">
            <ul className="flex items-center  font-visby w-fit mx-auto">
              {navitems.map((item, i) => (
                <li key={i} className="ml-12 text-lg text-black">
                  {item.sublinks.length > 0 ? (
                    <CustomDropDown name={item.name} items={item.sublinks} />
                  ) : (
                    <Link to={item.link}>{item.name}</Link>
                  )}
                </li>
              ))}
              <li className="ml-12 text-lg text-black">
                <a href="https://vertgrid.com" target="_blank" rel="noreferrer">
                  <button className="border-2 border-black text-base text-black font-bold uppercase py-3 px-5 rounded-[200px] flex items-center">
                    Vertgrid
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* mobile menu */}
      <div className="lg:hidden w-10/12 mx-auto " ref={navref}>
        <div className="flex items-center justify-between">
          <Link to="/" className="">
            <img src={Logo} alt="logo" />
          </Link>

          <button
            className="outline-none p-2 rounded focus:outline-none"
            onClick={toggleMenu}
          >
            <img src={MobileNavIcon} alt="" />
          </button>
        </div>

        <div
          className={`${
            mobileMenu
              ? "flex absolute top-0 right-0 bg-white h-full min-h-[100vh]  w-2/3 md:w-2/6 text-[#333] shadow-lg rounded-tl-3xl rounded-bl-3xl pl-8  flex-col items-between overflow-y-auto z-[80]"
              : "hidden"
          } `}
        >
          <div className="">
            <div className="flex items-center justify-end mb-6">
              <span
                className="font-semibold text-2xl text-black mr-12 mt-5"
                onClick={handleClose}
              >
                X
              </span>
            </div>
            <ul className=" text-start font-visby ">
              {navitems.map((item, i) => (
                <li key={i} className="mb-8 text-lg text-[grey2]">
                  {item.sublinks.length > 0 ? (
                    <CustomDropDown name={item.name} items={item.sublinks} />
                  ) : (
                    <Link onClick={handleClose} to={item.link}>
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
              <li className=" text-lg text-black">
                <a href="https://vertgrid.com" target="_blank" rel="noreferrer">
                  <button className="border-2 border-black text-base text-black font-bold uppercase py-3 px-5 rounded-[200px] flex items-center">
                    Vertgrid
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
