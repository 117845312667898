import { toast } from "react-toastify";
// Import Styling
import "react-toastify/dist/ReactToastify.css";


export function warning(string) {
  toast.warning(string);
}

export function errorToast(string) {
  toast.error(string);
}

export function success(string) {
  toast.success(string);
}
