import { Link } from "react-router-dom";
import { Arrow } from "../../assets/icons";
const ArrowButton = ({ title, link }) => {
  return (
    <Link
      className="border-2 border-darkTeal text-sm md:text-base lg:text-lg text-darkTeal font-bold uppercase py-3 lg:py-4 px-5 lg:px-8 rounded-[200px] 
      w-fit flex items-center"
      to={link}
    >
      {title} <img src={Arrow} alt="" className="ml-3 w-8 md:w-10 lg:w-12 xl:w-14" />
    </Link>
  );
};

export default ArrowButton;
