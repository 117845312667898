import {
  PlugSpotEImage,
  PlugSpotRImage,
  PlugSpotUImage,
  PlugspotE,
  PlugspotR,
  PlugspotU,
  Vertgrid,
  BlogImage,
} from "../assets/images";

export const products = [
  {
    id: `plugspot-r`,
    name: "Plugspot-r",
    description: `PluGSpot-R is a smart solar power system that 
        provides reliable electricity to telecommunication 
        equipment deployed in rural communities where 
        reliance on solar is the utmost priority. It is a 
        modular off-grid system with features tailored 
        to deliver the unique energy requirements of 
        rural telephony sites. They are scalable and solve 
        the challenges of deploying simple, repeatable, 
        and pre-configured power systems while 
        minimizing both CapEx and OpEx in meeting 
        a 99.9% uptime.`,
    image1: PlugspotR,
    image2: PlugSpotRImage,
    features: [
      `Lifetime free after-sales support.`,
      `Inbuilt system redundancy`,
      `Simple Network Management Protocol (SNMP) compatible`,
      `Real time remote monitoring.`,
      `Optimizes power consumption to match supply.`,
      `Remote operation capability, Operate power site 
            remotely.`,
      `Monitors solar yield and senses shade and dust.`,
      `Predicts weather conditions and optimizes power 
            consumption to match futuristic solar yield.`,
      `72 hours of Autonomy enabled by smart power 
            consumption algorithm`,
      `Monitors all site powered devices`,
      `Provides control and alarms if programmed 
            parameters exceed setting thresholds.`,
      `Stores data locally during periods when connectivity 
            is not available.`,
      `Anti-Battery theft technology`,
    ],
  },
  {
    id: `plugspot-u`,
    name: "Plugspot-u",
    description: `PluGSpot-U is an all-in-one poor grid/off-grid 
    pre-configured and cost-effective power 
    system that provides reliable electricity for 
    telecommunication equipment deployed in 
    peri-urban and urban areas providing uptime 
    of 99.9%. It is a modular and scalable system 
    with features tailored to deliver the unique 
    energy requirements of such sites.`,
    image1: PlugspotU,
    image2: PlugSpotUImage,
    features: [
      `Lifetime free after-sales support.`,
      `Incorporates three sources of power; solar panels, Grid and Diesel generator`,
      `Realtime monitoring.`,
      `Simple Network Management Protocol (SNMP) compatible`,
      `Monitors all available power sources and makes use of the cheapest and reliable source.`,
      `Auto restart capability.`,
      `Site surveillance`,
      `Detailed power analysis`,
      `Remote operation capability, Send commands to power site remotely).`,
      `Monitors all site power devices`,
      `Provides control and alarms if programmed parameters exceed setting thresholds.`,
      `Stores data locally during periods when connectivity is not available.`,
      `Anti-Battery theft technology`,
    ],
  },
  {
    id: `plugspot-e`,
    name: "Plugspot-e",
    description: `Plugspot-E is an all-in-one energy storage 
    system which has an inverter, solar controllers, 
    grid chargers and lithium batteries in a sleek and 
    compact enclosure that is easily mounted on 
    the wall or sits on a flat surface. It is a modular 
    and scalable system with features tailored to 
    deliver the unique energy requirements of 
    businesses.`,
    image1: PlugspotE,
    image2: PlugSpotEImage,
    features: [
      `Lifetime free after-sales support.`,
      `Inbuilt system redundancy`,
      `Realtime monitoring.`,
      `Scalable`,
      `PayGo Enabled`,
      `Auto restart capability.`,
      `Site surveillance`,
      `Detailed power analysis`,
      `Remote operation capability, Send commands to power site remotely).`,
      `Monitors solar yield and senses shade and dust.`,
      `Monitors all site power devices`,
      `Predicts weather conditions and optimizes power consumption to match futuristic solar yield.`,
      `Provides control and alarms if programmed parameters exceed setting thresholds, including 
      disconnecting and reconnecting loads, sending alarms if battery voltage or temperatures are 
      too high or low, etc.`,
      `Stores data locally during periods when connectivity is not available.`,
    ],
  },
  {
    id: `vertgrid`,
    name: "Vertgrid",
    description: `VertGrid is an artificial intelligence enabled remote monitoring platform that provides real-time remote control, management, and optimal operation of PluGSpot systems. It has inbuilt a functionality for managing site devices and site personnels. Additionally, Vertgrid offers integration with smart devices and appliances, allowing users to remotely control and manage their energy usage.`,
    image1: Vertgrid,
    image2: Vertgrid,
    features: [
      `Real time intelligence and fault management`,
      `Data Analytics`,
      `Remote Diagnosis`,
      `Artificial intelligence enabled`,
      `Predicts weather conditions and optimizes power consumption to match futuristic solar yield.`,
      `Up to 30 years of system reporting per device/site`,
      `Offers integration with smart devices and appliances, allowing users to 
      remotely control and manage their energy usage`,
      `Generate reports and alerts to help users stay informed about your site energy 
      consumption and identify opportunities for cost savings`,
    ],
  },
];

export const blogdata = [
  {
    id: "wix1",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company.",
    image: BlogImage,
  },
  {
    id: "wix2",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix3",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix4",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix5",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix6",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix7",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix8",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix9",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
  {
    id: "wix10",
    title: "Energy Management Systems and its uses",
    shortDescription:
      "Energy management systems are built to help manage the energy system for your sites and company blah blah blah  ",
    image: BlogImage,
  },
];

export const navitems = [
  {
    name: "Home",
    link: "/home",
    sublinks: [],
  },
  {
    name: "Products",
    link: "/",
    sublinks: [
      { name: "Plugspot-R", link: `/products/plugspot-r` },
      { name: "Plugspot-U", link: `/products/plugspot-u` },
      { name: "Plugspot-E", link: `/products/plugspot-e` },
      { name: "Vertgrid", link: `/products/vertgrid` },
    ],
  },
  {
    name: "Application",
    link: "/application",
    sublinks: [],
  },
  {
    name: "Blog",
    link: "/blog",
    sublinks: [],
  },
  {
    name: "Career",
    link: "/career",
    sublinks: [],
  },
  {
    name: "Contact Us",
    link: "/contact-us",
    sublinks: [],
  },
];

export const policies = [
  {
    topic: "Scope of this Policy",
    body: [
      `This policy governs all personally identifiable information 
    collected by Cellone and includes, but is not limited to, 
    information collected on our website, at industry events, 
    through customer visits, via phone and email, or otherwise 
    submitted to us directly. In addition, this includes, but is not 
    limited to information collected from users of our site and 
    users of our products and services. This policy does not cover 
    third party sites linked to on our website, nor does this policy 
    cover the practices of our third party partners.
    `,
      `
    Cellone is committed to protecting the privacy of our 
    customers, investors, partners and website visitors. 
    This privacy policy explains how we collect, use, and 
    share information when you visit or make a purchase 
    from our website.`,
    ],
    subInfo: [],
  },
  {
    topic: "Information We Collect",
    body: [
      `We collect information from you when you place an order, fill out a form, or enter information on our site. This may include your name, email address, mailing address, phone number, and payment information.`,
      `We may also collect information about your device and internet connection, including your IP address, browser type, and operating system.`,
    ],
    subInfo: [],
  },
  {
    topic: "How We Use Your Information",
    body: [
      `We use the information we collect from you to process orders, respond to your inquiries, and provide you with the best possible customer service. We may also use your information to send you marketing communications or to personalize your experience on our website.`,
    ],
    subInfo: [],
  },
  {
    topic: "Sharing Your Information",
    body: [
      `We will not sell or rent your personal information to third parties. We may share your information with service providers who assist us with our business operations, such as payment processors or shipping companies. These service providers are required to maintain the confidentiality of your information and are not permitted to use it for any other purpose.`,
      `We may also disclose your information as required by law or to protect the rights and safety of our company and customers.`,
    ],
    subInfo: [],
  },
  {
    topic: "Cookies and Tracking Technologies",
    body: [
      `We use cookies and other tracking technologies to improve the performance of our website and to personalize your experience. You can adjust your browser settings to disable cookies, but this may affect your ability to use certain features of our website.`,
    ],
    subInfo: [],
  },
  {
    topic: "Security Measures",
    body: [
      `We take appropriate security measures to protect against unauthorized access to or alteration of your personal information. We use secure servers, firewalls, and other technologies to protect your information.`,
    ],
    subInfo: [],
  },
  {
    topic: "Changes to this Policy",
    body: [
      `We reserve the right to update this privacy policy at any time. Any changes will be effective immediately upon posting to this page. We encourage you to review this policy periodically to stay informed about how we are protecting your information.`,
    ],
    subInfo: [],
  },
];

export const faqQuestions = [
  {
    question: "How does Cellone solar power work?",
    answer: [
      `The cellone solar system called plugspot is a modular and scalable pre-configured power system that provides cheaper and reliable electricity for telecom infrastructures, homes and enterprises.`,
    ],
  },
  {
    question: "How long does the Cellone solar power system last?",
    answer: [
      ` The cellone solar power system provides electricity round the clock for appliances connected to it. With its smart load control system, it can provide power for up to 3 days for telecom equipment.`,
    ],
  },
  {
    question: "How much money can I save with a Cellone’s solar power system?",
    answer: [
      `The amount of money that you can save with the Cellone solar power system depends on several factors, including the size of your solar panel array, the amount of electricity you use, and your local utility rates. In general, the larger your solar panel array, the more electricity you will produce and the more money you will save. Additionally, if you use a lot of electricity and have high utility rates, you will likely save more money with the Cellone solar power system than if you use less electricity and have lower utility rates.`,
      `To get a more accurate estimate of how much money you can save with the Cellone solar power system, please reach out to us. `,
    ],
  },
  {
    question: "Do the Cellone solar power systems require maintenance?",
    answer: [
      `Overall, the Cellone solar power systems are relatively low maintenance and should not require much time or effort to keep them in good working order. Some of the required maintenance includes cleaning the solar panels, and general Inspection of the system.`,
    ],
  },
  {
    question:
      "How long does it take to install the Cellone solar power system?",
    answer: [
      `After the consultation with our experts and the design of an adequate solar system size, we install the system within 3-10 working days irrespective of your location. `,
    ],
  },
  {
    question: "Do the Cellone solar power systems work on cloudy days?",
    answer: [
      `Yes. We have an advanced solar system which enables it to generate more electricity on cloudy days than anticipated.`,
    ],
  },
  {
    question: "Are the Cellone solar power systems safe?",
    answer: [
      `Yes. They do not produce any emissions or pollutants and do not pose any significant risks to human health or the environment.`,
    ],
  },
  {
    question: "How do I finance a Cellone solar power system?",
    answer: [
      `Please reach out to our experts to provide you with a unique financing process that fits your budget.`,
    ],
  },
  {
    question: "Are solar power systems eligible for government incentives?",
    answer: [`Yes.`],
  },
  {
    question:
      "Can the Cellone solar power systems be used for commercial buildings?",
    answer: [`Yes.`],
  },
  {
    question: "Are Cellone’s solar power systems covered by warranties?",
    answer: [
      `Yes. We offer a 7 years warranty and a life-time free after sales support.`,
    ],
  },
];
