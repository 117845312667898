import React from "react";

const ProductHeader = ({ title }) => {
  return (
    <div className="bg-darkTeal text-white p-3  w-full h-52  md:h-60 lg:h-[320px] flex items-center ">
      <div className="w-10/12 mx-auto">
        <h3 className="font-semibold text-4xl  md:text-5xl lg:text-7xl uppercase font-clash">
          {title}
        </h3>
      </div>
    </div>
  );
};

export default ProductHeader;
