import React from "react";

const Spinner = ({ md, lg, xl, white }) => {
  return (
    <div className="">
      <div
        className={`border-t-transparent border-solid animate-spin  rounded-full border-darkTeal ${
          white ? "border-white" : "border-darkTeal"
        } border-4 h-8 w-8 ${md ? "w-16 h-16" : "h-8 w-8"} ${
          lg ? "w-32 h-32" : " w-8 h-8"
        } ${
            xl ? "w-48 h-48" : " w-8 h-8"
          }`}
      ></div>
    </div>
  );
};

export default Spinner;
