import React, { useEffect } from "react";
import { Header } from "../components/shared";
import { policies } from "../data";
import { CheckCircle } from "../assets/icons";

const PrivacyPolicy = (props) => {
  const scrollToTiem = (index, auto) => {
    const item = document.getElementById(`item${index}`);
    item?.scrollIntoView({
      behavior: auto ? "auto" : "smooth",
    });
  };

  useEffect(() => {
    if (
      props &&
      props?.match &&
      props?.match?.params &&
      props?.match?.params?.topic
    ) {
      const topic = props.match.params.topic;
      scrollToTiem(topic, "auto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header title="PRIVACY POLICY" />
      <div className="my-16 w-full px-6 md:px-12 lg:px-0  lg:w-10/12 mx-auto">
        <div className="  grid grid-cols-12 gap-6 font-clash ">
          <div className="hidden md:block md:col-span-5 ">
            <div className="text-black">
              {policies.map((itemTopic, index) => {
                return (
                  <div
                    className=" cursor-pointer mb-6 flex items-center py-3"
                    key={index}
                    onClick={() => scrollToTiem(index)}
                  >
                    <img src={CheckCircle} alt="" className="w-5 h-5 mr-3" />
                    <p className="font-medium text-lg lg:text-xl xl:text-2xl">
                      {itemTopic.topic}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-12 md:col-span-7 ">
            <div>
              {policies.map((item, index) => {
                return (
                  <div className="py-3" key={index} id={"item" + index}>
                    <h3 className="font-semibold text-lg xl:text-3xl text-black">
                      {item.topic}
                    </h3>
                    {item.body.map((itemBody, subIndex) => {
                      return (
                        <p
                          className="text-grey1 text-sm lg:text-lg mb-4"
                          key={subIndex}
                        >
                          {itemBody}
                        </p>
                      );
                    })}
                    {item.subInfo.map((subItem, subIndex) => {
                      return (
                        <p className="faint-font reduced-soft" key={subIndex}>
                          {subItem}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="font-clash mt-8">
          <h5 className="font-semibold text-lg xl:text-3xl text-black">
            Contact Us
          </h5>
          <p className="text-grey1 text-sm lg:text-lg">
            If you have any questions or concerns about our privacy policy,
            please contact us by email
            <br />
            at  
            <a
                href="mailto:hr@cellonetechnologies.com"
                className="underline ml-1"
              >
                contact@cellonetechnologies.com
              </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
