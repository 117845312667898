import React from "react";

const YellowBorderTitle = ({ title }) => {
  return (
    <h5 className="text-xl font-semibold w-fit py-1 border-b-2 border-b-yellow uppercase">
     {title}
    </h5>
  );
};

export default YellowBorderTitle;
