import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <ToastContainer
        autoClose={3000}
        draggable={false}
        hideProgressBar={false}
        position={"top-right"}
        theme={"light"}
        newestOnTop
      />
    </BrowserRouter>
  </React.StrictMode>
);
