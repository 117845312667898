import React from 'react'
import { Header } from "../components/shared";


const Blog = () => {
  return (
    <div>
      <Header title="BLOG" />
    </div>
  )
}

export default Blog