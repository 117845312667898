import React from "react";

const Label = ({ label, grey }) => {
  return (
    <p
      className={` ${
        grey ? "text-grey1" : ""
      } mb-2  text-sm md:text-base text-black font-medium`}
    >
      {label}
    </p>
  );
};

export default Label;
