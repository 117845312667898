import { useState } from "react";
import YellowBorderTitle from "./YellowBorderTitle";
import ErrorMessage from "./ErrorMessage";
import { errorToast, success } from "./Toast";
import Spinner from "./Spinner";
import { LocationWhite, EmailWhite, PhoneWhite } from "../../assets/icons";
import { useForm } from "react-hook-form";
import { Arrow } from "../../assets/icons";
import axios from "axios";
const ContactSection = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getServiceMail = async (data) => {
    switch (data) {
      case "technical":
        return "https://backend.vertgrid.com/email/landing/technicalSupport";

      case "distribution":
        return "https://backend.vertgrid.com/email/landing/distributor";

      case "investment":
        return "https://backend.vertgrid.com/email/landing/investor";

      case "buying":
        return "https://backend.vertgrid.com/email/landing/product";

      default:
        return "https://backend.vertgrid.com/email/landing/product";
    }
  };

  const submitToEmail = async (data) => {
    const serviceUrl = await getServiceMail(data.service);
    if (serviceUrl !== null || "") {
      const emaildata = {
        fullName: data.fullName,
        emailAddress: data.emailAddress,
        message: data.message,
        locationCountry: "",
        locationState: "",
        phoneNumber: "",
        organization: "",
      };

      return await axios
        .post(`${serviceUrl}`, emaildata)
        .then((res) => {
          console.log(res.data);
          const { type, msg } = res.data;
          if (type === "success") {
            success(msg);
            return true;
          } else {
            setLoading(false);
            errorToast(msg);
            return false;
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response.message);
        });
    } else {
      errorToast("please select a service");
      return;
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);

    const result = await submitToEmail(data);
    if (result) {
      setLoading(false);
      reset({
        service: "",
        fullName: "",
        emailAddress: "",
        message: "",
      });
      return;
    }
  };
  return (
    <div className="bg-contact bg-no-repeat bg-cover bg-center py-8 mb-16 relative font-clash">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/70"></div>
      <div className=" w-11/12 md:w-10/12  mx-auto grid grid-cols-1 lg:grid-cols-2 gap-y-8  relative">
        <div className="col-span-1 text-white font-clash flex flex-col justify-center">
          <div className="mb-4">
            <YellowBorderTitle title="CONTACT US" />
          </div>
          <div>
            <div className="flex items-center mb-5">
              <img src={LocationWhite} alt="" className="mr-3" />
              <span className="font-medium text-sm sm:text-base md:text-lg xl:text-xl">
                13, Olufemi road, off Ogunlana drive, Surulere, Lagos.
              </span>
            </div>
            <div className="flex items-center mb-5">
              <img src={PhoneWhite} alt="" className="mr-3" />
              <span className="font-medium text-sm sm:text-base md:text-lg xl:text-xl">
                +2349160370984
              </span>
            </div>
            <div className="flex items-center">
              <img src={EmailWhite} alt="" className="mr-3" />
              <span className="font-medium text-sm sm:text-base md:text-lg xl:text-xl">
                contact@cellonetechnologies.com
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="col-span-1 ">
            <div className=" w-full xl:w-11/12 mx-auto bg-white px-6 py-12  rounded-lg">
              <div className="contact mb-6">
                <select
                  className="contact-select pl-20"
                  {...register("service", {
                    // required: { value: true, message: "Full Name is required" },
                  })}
                >
                  <option>What service do you need?</option>
                  <option value="technical">Technical Support</option>
                  <option value="distribution">Become a Distributor</option>
                  <option value="investment">Become an Investor</option>
                  <option value="buying">Buy a product</option>
                </select>
                {errors.service && (
                  <ErrorMessage message={errors.service?.message} />
                )}
              </div>
              <div className="mb-6">
                <input
                  className="input"
                  placeholder="Enter full name"
                  {...register("fullName", {
                    required: { value: true, message: "Full Name is required" },
                  })}
                />
                {errors.fullName && <ErrorMessage message={errors.fullName?.message} />}
              </div>
              <div className="mb-6">
                <input
                  className="input"
                  placeholder="Enter email address"
                  {...register("emailAddress", {
                    required: { value: true, message: "Full Name is required" },
                  })}
                />
                {errors.emailAddress && (
                  <ErrorMessage message={errors.emailAddress?.message} />
                )}
              </div>
              <div className="mb-6">
                <textarea
                  className="input resize-none h-60"
                  placeholder="Write your message"
                  {...register("message", {
                    // required: { value: true, message: "Full Name is required" },
                  })}
                ></textarea>
                {errors.message && (
                  <ErrorMessage message={errors.message?.message} />
                )}
              </div>

              <div>
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="contact-us-button"
                >
                  {loading ? <Spinner /> : "SEND MESSAGE"}
                  <img
                    src={Arrow}
                    alt=""
                    className="ml-3 w-8 md:w-10 lg:w-12 xl:w-14"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
